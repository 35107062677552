.menu {
    .menu__table {
        white-space: pre;
        min-width: 400px;
        overflow: auto;

        img {
            height: 90px;
            width: 90px;
            border-radius: .25rem;
            object-fit: cover;

            @include media(580px) {
            height: 60px;
            width: 60px;
            }
        }
    }
}