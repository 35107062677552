.orders-live {
    .orders-live-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow: hidden;
        // flex-wrap: nowrap;
        // align-items: stretch;
        // justify-content: center;
        // overflow: hidden;
        // height: 100%;
    }

    .order-list {
        display: flex;
        max-width: 300px;
        min-width: 300px;
        overflow-y: auto;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        // background-color: #071c2f;

        @include media(767px) {
            min-width: 100%;
            max-width: 100%;
        }

        .list-item {
            justify-content: space-between;
            border-bottom: 1px solid #ddd;
            position: relative;
            background-color: #e8e9ea;
            color: #292929;

            &.active {
                color: #fff !important;
                background-color: #1a2038 !important;

                &::before {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 100%;
                    left: 0;
                    background-color: #fff;
                }

                // background-color: #fff;
            }

            &:hover {
                opacity: .8;
            }

            .list-item__meta::first-letter {
                text-transform: capitalize;
            }

            .item__status {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 60px;
                // margin-left: 1rem;
                text-align: center;
                // padding: 8px 0;
                color: #fff;
                background-color: #f44336;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .not-accepted {
                    font-size: .8em;
                }

                .label {
                    font-size: 10px;
                    // margin-bottom: .1rem;
                    font-weight: 600;
                }

                .countdown {
                    color: #fff;
                    border-color: #fd7e14;
                    // background: #fff;
                    display: flex;
                    // float: right;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    width: 36px;
                    height: 36px;
                    margin: 0 auto;
                    border-radius: 6px;
                    // border: 2px solid #bbb;
                    // background-color: #fff;




                    .number {
                        display: block;
                        padding: 0;
                        margin: -2px auto 0;
                        font-size: 22px;
                        font-weight: 700;
                    }

                    .min {
                        margin-top: -4px;
                        font-size: 8px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .order-details {
        position: relative;
        width: 100%;
        max-width: 740px;
        margin: 0;
        background: #fff;
        -webkit-box-shadow: 10px 0 10px -6px #e8e9ea;
        box-shadow: 10px 0 10px -6px #e8e9ea;

        .order-details-close {
            display: none;
        }

        @include media(767px) {
            display: flex;
            flex-direction: column;
            position: fixed;
            // top: 55px;
            top: 0;
            left: 0;
            width: 100vw;
            max-width: 100vw;
            // height: calc(100% - 55px);
            height: calc(100%);
            z-index: 999;
            transform: translate(100%);
            transition: all .2s ease-out;

            &.open {
                transform: translate(0);
            }

            .order-details-close {
                display: block;
            }
        }
    }
}

#print-area {
    display: none;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 999 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible;
    font-size: 1rem;
    font-family: Arial !important;
    color: #000000 !important;
}

// Media print
@media print {

    body,
    *,
    html {
        visibility: hidden;
    }

    .ps {
        overflow: scroll !important;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;
    }

    .no-print {
        display: none !important;
    }

    .sidenav {
        display: none !important;
    }

    #print-area,
    .print-area {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // height: 100%;
        // // width: 800px;
        margin: 0;
        padding: 0;
        visibility: visible;
        // font-size: 1rem;
        font-family: Arial !important;
        color: #000000 !important;

        * {
            visibility: visible;
        }

        .row {
            display: flex;
        }

        .col-6 {
            width: 50%;
            display: flex;
        }

        .no-print {
            display: none !important;
        }
    }
}

.bg-placed {
    background-color: #f44336 !important;
}

.bg-accepted {
    background-color: #D98E00 !important;
}

.bg-in-route,
.bg-ready {
    background-color: #197BC3 !important;
}

.picked-up,
.delivered {
    background-color: #07ad6c !important;
}

@keyframes order-status-blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes order-status-blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes order-countdown-ready-blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    to {
        opacity: 1;
    }
}

@keyframes order-countdown-ready-blink {
    0% {
        background-color: #6f42c1;
        color: #fff
    }

    50% {
        background-color: #fff;
        color: #292929
    }

    to {
        background-color: #6f42c1;
        color: #fff
    }
}

@-webkit-keyframes order-countdown-accepted-blink {
    0% {
        background-color: #fd7e14;
        color: #fff
    }

    50% {
        background-color: #fff;
        color: #292929
    }

    to {
        background-color: #fd7e14;
        color: #fff
    }
}

@keyframes order-countdown-accepted-blink {
    0% {
        background-color: #fd7e14;
        color: #fff
    }

    50% {
        background-color: #fff;
        color: #292929
    }

    to {
        background-color: #fd7e14;
        color: #fff
    }
}

.blink {
    animation: order-status-blink 1.25s -.4s infinite;
}

@page {
    margin: 0px;
}